<template>
  <v-app>
    <router-view v-if="configStore.configuracoes"></router-view>
    <GlobalComponents />
  </v-app>
</template>

<script>
import {defineComponent} from 'vue'
import {useConfigStore} from '@/store/Config'
import {setLoader, emitirDialog} from '@/store/GlobalComponents'
import GlobalComponents from '@/components/GlobalComponents.vue'
export default defineComponent({
  name: 'App',
  components: {GlobalComponents},
  setup() {
    const configStore = useConfigStore()
    setLoader(true, 'Carregando configurações do sistema')
    configStore.carregarConfiguracoes().catch(() => {
      emitirDialog('Não foi possível carregar as configurações do site. Sua conexão pode estar instável ou o serviço temporariamente indisponível. Recarregue a página e tente novamente. Caso o problema persista por mais tempo, entre em contato conosco.')
    }).finally(() => {
      setLoader(false)
    })
    return {configStore}
  },
  mounted() {
    const theme = localStorage.getItem('cache_theme');
    if (theme != null) this.$vuetify.theme.dark = theme === '1';
  },
  methods: {
    onThemeChange() {
      document.querySelector('meta[name="theme-color"]').setAttribute('content',  this.$vuetify.theme.dark ? '#272727' : '#1976D2');
      localStorage.setItem('cache_theme', this.$vuetify.theme.dark ? '1' : '0');
    },
  },
  computed: {
    darkMode() {return this.$vuetify.theme.dark},
  },
  watch: {
    darkMode() {this.onThemeChange()},
  },
})
</script>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 300ms;
    transition-property: opacity;
    transition-timing-function: ease-out;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-leave,
  .fade-enter-to {
    opacity: 1;
  }

  .body-background {
    background-color: #f5f6f8;
  }
  .titulo-destacado {
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
  }
  .titulo-destacado:before, .titulo-destacado:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: calc(50% - 1rem);
    height: 1px;
    content: '\a0';
    background-color: grey;
    margin-left: 1rem;
  }
  .titulo-destacado:before {
    margin-left: -50%;
    text-align: right;
  }

  .v-application--is-ltr .v-data-footer__select div.v-select {
    margin-left: 4px;
  }
  .v-application--is-ltr div.v-data-footer__select {
    margin-right: auto;
    margin-left: 6px;
  }
  .v-application--is-ltr div.v-data-footer__pagination {
    margin-left: 6px;
    margin-right: 0;
  }
  thead.v-data-table-header th {
    white-space: nowrap;
  }
  .v-dialog:not(.v-dialog--fullscreen) {
    margin: .8rem !important;
  }

  @media print {
    .v-navigation-drawer {
      display: none !important;
    }
    .v-main {
      padding: 0 !important;
    }
  }
</style>
